import { EllipsisSpan, MxColumnType } from '@maxtropy/components';
import * as React from 'react';
import { parseOnDisplay } from '../../../FormulaInput';
import { getRange } from '../../../../utils/utils';

export const columnsBefore: MxColumnType<any>[] = [
  {
    title: '数据属性/标识符',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (_: any, record) => (
      <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
    ),
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitGeneralName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export const getColumns = (map: Map<string, string>): MxColumnType<any>[] => [
  {
    title: '虚拟点类型',
    dataIndex: 'hasProperty',
    ellipsis: { showTitle: true },
    render: (value: boolean) => <EllipsisSpan value={value ? '建模虚拟点' : '非建模虚拟点'} />,
  },
  {
    title: '是否可写',
    dataIndex: 'writable',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? '是' : '否'} />,
  },
  {
    title: '公式',
    dataIndex: 'formula',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={parseOnDisplay(v, map)} />,
  },
  {
    title: '数值合理区间',
    dataIndex: 'valueRange',
    ellipsis: { showTitle: true },
    render: (_: any, record) => (
      <EllipsisSpan value={getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling)} />
    ),
  },
  {
    title: '备注',
    dataIndex: 'remark',
    ellipsis: { showTitle: true },
    render: (value: string) => <EllipsisSpan value={value} />,
  },
];
