import React, { useContext } from 'react';
import { EllipsisSpan, FieldTable, Modal, MxColumnType, MxColumnsType, Table } from '@maxtropy/components';
import { PointContext, PointContextProps } from '../../../contextTypes';
import { getColumns } from './columns';
import { EdgeDeviceTemplatePoint } from './index';
import { DataPointType, DataProperty } from '../../../../../types';
import { IEnum } from '../utils';
import { uniqueId } from 'lodash-es';

interface ModbusPointBaseProps {
  fixed?: boolean;
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  dataPropertiesAll?: DataProperty[];
}

export const getIndexColumn = (fixed?: boolean): MxColumnType<EdgeDeviceTemplatePoint> => {
  return {
    title: '序号',
    width: 70,
    fixed: fixed ? 'left' : false,
    render: (_, record, index) => index + 1,
  };
};

const columns = [
  {
    title: '枚举',
    dataIndex: 'enum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '枚举值名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '采集（原始）值',
    dataIndex: 'value',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
];

const ModbusPointBase: React.FC<ModbusPointBaseProps> = props => {
  const { fixed, loading, editColumns, dataPropertiesAll } = props;

  const { dataSource, canAdjustField } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;

  const showMappingInfo = (values: IEnum[]) => {
    Modal.info({
      title: '枚举映射',
      width: 640,
      content: (
        <Table
          style={{ marginBottom: '10px' }}
          rowKey="enum"
          dataSource={values}
          columns={columns}
          pagination={false}
        />
      ),
    });
  };

  return (
    <>
      <FieldTable
        sticky
        loading={loading}
        scroll={{ y: 500 }}
        rowKey={uniqueId()}
        cacheKey="modbusPoint"
        adjustField={canAdjustField}
        adjustWidth={canAdjustField}
        dataSource={dataSource?.filter(item => item.pointType === DataPointType.BASE_POINT)}
        columns={[
          getIndexColumn(fixed),
          ...getColumns(fixed, dataPropertiesAll, showMappingInfo, !canAdjustField ? [] : editColumns),
          ...(canAdjustField ? [] : editColumns || []),
        ]}
      />
    </>
  );
};

export default ModbusPointBase;
