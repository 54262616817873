import React, { useCallback, useContext, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ActionType, DataPointType, StaticPoint } from '../../../../types';
import Header from '../Header';
import { PointContext, PointContextProps } from '../../contextTypes';
import { columnsAfter, columnsBefore } from './columns';
import { useQuery } from '../../../../utils/utils';
import { getDataProperties } from '../../../../api/options';
import FormDialog from './FormDialog';
import { Button, FieldTable, MxColumnType, MxColumnsType } from '@maxtropy/components';

export type EdgeDeviceTemplatePoint = StaticPoint & { actionType?: ActionType };

interface StaticPointProps {
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
}

const indexColumn: MxColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  render: (_, record, index) => index + 1,
};

const Static: React.FC<StaticPointProps> = props => {
  const { loading, editColumns, onUpdate } = props;

  const { dataSource, row, setRow, info, promptSlot, editable, canAdjustField } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;

  const { data: dataPropertiesAll = [] } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const usedProperties = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId) || [],
    [dataSource, row]
  );

  const usedComputationalLogic = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item?.computationalLogic !== row?.computationalLogic)
        .map(item => item?.computationalLogic)
        .filter(Boolean) || [],
    [dataSource, row]
  );

  const onOk = (values: any) => {
    const _values = {
      ...values,
      pointType: DataPointType.STATIC_POINT,
    };
    onUpdate?.(_values);
  };

  return (
    <>
      <Header slot={promptSlot}>
        {editable && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setRow?.({ pointType: DataPointType.STATIC_POINT })}
          >
            添加静态点
          </Button>
        )}
      </Header>
      <FieldTable
        sticky
        rowKey="id"
        adjustField={canAdjustField}
        adjustWidth={canAdjustField}
        cacheKey="staticPoint"
        scroll={{ y: 500 }}
        loading={loading}
        dataSource={dataSource?.filter(item => item.pointType === DataPointType.STATIC_POINT)}
        columns={[indexColumn, ...columnsBefore, ...(editColumns || []), ...columnsAfter]}
        pagination={false}
      />
      {row?.pointType === DataPointType.STATIC_POINT && (
        <FormDialog
          onCancel={() => setRow?.(undefined)}
          onOk={onOk}
          usedProperties={usedProperties}
          usedComputationalLogic={usedComputationalLogic}
          dataPropertiesAll={dataPropertiesAll}
          row={row}
        />
      )}
    </>
  );
};

export default Static;
