import { EllipsisSpan } from '../EllipsisSpan';
import * as React from 'react';
import { ColumnType } from 'antd/es/table';
import { ComputationalLogic, computationalLogicDisplay } from '../../../../types';

const columns: ColumnType<any>[] = [
  {
    title: '数据属性/标识符',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (_: any, record) => <EllipsisSpan value={record.dataPropertyName} />,
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitGeneralName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? v : '--'} />,
  },
  {
    title: '计算逻辑',
    dataIndex: 'computationalLogic',
    ellipsis: { showTitle: true },
    render: (v: ComputationalLogic) => <EllipsisSpan value={computationalLogicDisplay[v]} />,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default columns;
